import '../../assets/css/text.css'
import '../../assets/css/cards.scss'

export default function ProfileSidebar(){
    return (
        <div>
            <div className={'title-text'}>Profile</div>
            <div className={'card-grid'}>
                <div className={'grid-card'}>My Details</div>
                {/*<div className={'grid-card'}>Surveys</div>*/}
                {/*<div className={'grid-card'}>My Projects</div>*/}
            </div>
        </div>
    )
}