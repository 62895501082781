import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import axios, { AxiosError } from "axios";
import { useNavigate, Link } from 'react-router-dom';
import {useAuth} from "../components/auth/AuthProvider";

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '450px',
    },
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const RegisterContainer = styled(Stack)(({ theme }) => ({
    padding: 20,
    marginTop: '10vh',
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));

interface ErrorResponseData {
    email?: string;
    password?: string;
    first_name?: string; // Add first name error field
    last_name?: string; // Add last name error field
}

export default function Register() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [passwordConfirmationError, setPasswordConfirmationError] = React.useState(false);
    const [passwordConfirmationErrorMessage, setPasswordConfirmationErrorMessage] = React.useState('');
    const [firstNameError, setFirstNameError] = React.useState(false);
    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState('');
    const [lastNameError, setLastNameError] = React.useState(false);
    const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState('');

    const navigate = useNavigate();
    const {login} = useAuth();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        if (validateInputs()) {
            const data = new FormData(event.currentTarget);

            // Prepare user data for registration
            const userData = {
                email: data.get('email'),
                first_name: data.get('firstName'),
                last_name: data.get('lastName'),
                password: data.get('password'),
            };

            try {
                await axios.post(process.env.REACT_APP_API_BASE_URL + 'auth/register', userData, {
                    withCredentials: true
                }).then(response => {
                    console.log('Registered successfully');
                    login(response.data['user']);
                    navigate('/');
                });
            } catch (error) {
                setIsLoading(false);
                if (axios.isAxiosError(error)) {
                    const e = error as AxiosError<ErrorResponseData>; // Use generic to specify the expected data structure

                    // Check if the response exists and has data
                    if (e.response && e.response.data) {
                        const data = e.response.data; // This should now be correctly typed

                        if (data.email) {
                            setEmailError(true);
                            setEmailErrorMessage(data.email);
                        }
                        if (data.password) {
                            setPasswordError(true);
                            setPasswordErrorMessage(data.password);
                        }
                        if (data.first_name) {
                            setFirstNameError(true);
                            setFirstNameErrorMessage(data.first_name);
                        }
                        if (data.last_name) {
                            setLastNameError(true);
                            setLastNameErrorMessage(data.last_name);
                        }
                    }
                } else {
                    setIsLoading(false);
                    console.error(error);
                }
            }
        } else {
            setIsLoading(false);
        }
    };

    const validateInputs = () => {
        const email = document.getElementById('email') as HTMLInputElement;
        const password = document.getElementById('password') as HTMLInputElement;
        const passwordConfirmation = document.getElementById('passwordConfirmation') as HTMLInputElement;
        const firstName = document.getElementById('firstName') as HTMLInputElement;
        const lastName = document.getElementById('lastName') as HTMLInputElement;

        let isValid = true;

        // Validate first name
        if (!firstName.value) {
            setFirstNameError(true);
            setFirstNameErrorMessage('First name is required.');
            isValid = false;
        } else {
            setFirstNameError(false);
            setFirstNameErrorMessage('');
        }

        // Validate last name
        if (!lastName.value) {
            setLastNameError(true);
            setLastNameErrorMessage('Last name is required.');
            isValid = false;
        } else {
            setLastNameError(false);
            setLastNameErrorMessage('');
        }

        // Validate email
        if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
            setEmailError(true);
            setEmailErrorMessage('Please enter a valid email address.');
            isValid = false;
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }

        // Validate password
        if (!password.value || password.value.length < 6) {
            setPasswordError(true);
            setPasswordErrorMessage('Password must be at least 6 characters long.');
            isValid = false;
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        // Validate password confirmation
        if (password.value !== passwordConfirmation.value) {
            setPasswordConfirmationError(true);
            setPasswordConfirmationErrorMessage('Passwords do not match.');
            isValid = false;
        } else {
            setPasswordConfirmationError(false);
            setPasswordConfirmationErrorMessage('');
        }

        return isValid;
    };

    return (
        <RegisterContainer direction="column" justifyContent="space-between">
            <Card variant="outlined">
                <Typography
                    component="h1"
                    variant="h4"
                    sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                >
                    Register
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: 2,
                    }}
                >
                    <FormControl>
                        <FormLabel htmlFor="firstName" sx={{ textAlign: 'left' }}>First Name</FormLabel>
                        <TextField
                            name="firstName"
                            placeholder="John"
                            id="firstName"
                            required
                            fullWidth
                            variant="outlined"
                            error={firstNameError}
                            helperText={firstNameErrorMessage}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="lastName" sx={{ textAlign: 'left' }}>Last Name</FormLabel>
                        <TextField
                            name="lastName"
                            placeholder="Doe"
                            id="lastName"
                            required
                            fullWidth
                            variant="outlined"
                            error={lastNameError}
                            helperText={lastNameErrorMessage}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="email" sx={{ textAlign: 'left' }}>Email</FormLabel>
                        <TextField
                            error={emailError}
                            helperText={emailErrorMessage}
                            id="email"
                            type="email"
                            name="email"
                            placeholder="your@email.com"
                            autoComplete="email"
                            required
                            fullWidth
                            variant="outlined"
                            color={emailError ? 'error' : 'primary'}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="password" sx={{ textAlign: 'left' }}>Password</FormLabel>
                        <TextField
                            error={passwordError}
                            helperText={passwordErrorMessage}
                            name="password"
                            placeholder="••••••"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            required
                            fullWidth
                            variant="outlined"
                            color={passwordError ? 'error' : 'primary'}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="passwordConfirmation" sx={{ textAlign: 'left' }}>Confirm Password</FormLabel>
                        <TextField
                            error={passwordConfirmationError}
                            helperText={passwordConfirmationErrorMessage}
                            name="passwordConfirmation"
                            placeholder="••••••"
                            type="password"
                            id="passwordConfirmation"
                            required
                            fullWidth
                            variant="outlined"
                            color={passwordConfirmationError ? 'error' : 'primary'}
                        />
                    </FormControl>
                    <Button type="submit" variant="contained" disabled={isLoading}>{isLoading? 'Please wait...' : 'Register'}</Button>
                    <Typography>
                        Already have an account? <Link to="/login">Login</Link>
                    </Typography>
                </Box>
            </Card>
        </RegisterContainer>
    );
}
