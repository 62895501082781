import '../../assets/css/text.css'
import '../../assets/css/cards.scss'
import ProfileDetails from "./profile_details";
import ProfileContent from "./profile_content";
import MainLayout from "../../layouts/main_layout";
import ProfileSidebar from "../../components/profile/profile_sidebar";
import {useParams} from "react-router-dom";

interface Props {
    navSelection: string,
    setNavSelection: Function
}

export default function Profile(props: Props) {
    const params = useParams();

    return (
        <MainLayout
            navSelection={props.navSelection}
            setNavSelection={props.setNavSelection}
            sidebar={
                <ProfileSidebar/>
            }
            content={
                <div className="background-container">
                    <div className="background-card">
                        <div className={"profile-container"}>
                            <ProfileDetails id={params.id}/>
                            <ProfileContent id={params.id}/>
                        </div>
                    </div>
                </div>
            }
        />
    )
}