import '../../assets/css/text.css'
import '../../assets/css/cards.scss'
import ProfileDetails from "./profile_details";
import ProfileContent from "./profile_content";
import {useParams} from "react-router-dom";

export default function Person() {
    const params = useParams();

    return (
        <div className="background-container">
            <div className="background-card">
                <div className={"profile-container"}>
                    <ProfileDetails id={params.id}/>
                    <ProfileContent id={params.id}/>
                </div>
            </div>
        </div>
    )
}