import '../../assets/css/text.css'
import '../../assets/css/cards.scss'
import axios from "axios";
import {useEffect, useState} from "react";

interface ProfileTitleProps {
    title: string
}

function ProfileTitle(props: ProfileTitleProps) {
    return (
        <div className={'profile-details-title'}>
            {props.title}
        </div>
    )
}

interface ProfileItemProps {
    item: string
}

function ProfileItem(props: ProfileItemProps) {
    return (
        <div className={'profile-details-item'}>
            {props.item}
        </div>
    )
}

interface Props {
    id: string | undefined
}

export default function ProfileDetails(props: Props) {
    const [name, setName] = useState('No Name Found');
    const [email, setEmail] = useState('No Email Found');
    const [phone, setPhone] = useState(null);
    const [role, setRole] = useState(null);

    useEffect(() => {
        if(props.id){
            axios.post(process.env.REACT_APP_API_BASE_URL + 'object/details',
                {'id': props.id},
                {
                    withCredentials: true
                }
            ).then(response => {
                setName(response.data.first_name + ' ' + response.data.last_name);
                setEmail(response.data.email);
                setPhone(response.data.phone);
                setRole(response.data.role);
            }).catch(error => {
                console.error(error);
            });
        } else {
            axios.post(process.env.REACT_APP_API_BASE_URL + 'user/details',
                {'object_id': props.id},
                {
                    withCredentials: true
                }
            ).then(response => {
                setName(response.data.first_name + ' ' + response.data.last_name);
                setEmail(response.data.email);
                setPhone(response.data.phone);
                setRole(response.data.role);
            }).catch(error => {
                console.error(error);
            });
        }

    }, []);

    return (
        <div className="profile-card">
            <ProfileTitle title={name}/>
            {email && <ProfileItem item={email}/>}
            {phone && <ProfileItem item={phone}/>}
            {role && <ProfileItem item={role}/>}
        </div>
    )
}