import '../../assets/css/cards.scss'
import {FaEye} from "react-icons/fa";
import React from "react";
import {useNavigate} from "react-router-dom";
import {FaCircleNodes} from "react-icons/fa6";

interface Result {
    object_id: number,
    title: string,
    type: string,
    short_description: string,
}

interface Props {
    result: Result | null,
    selectHandler: Function
}


export default function SearchResult(props: Props) {

    const navigate = useNavigate();

    return (
        <div
            onClick={() => props.selectHandler(props.result?.object_id)}
            className={"search-result"}
        >
            <div style={{fontSize: "20px", fontWeight: "bold", marginBottom: "8px"}}>
                {props.result?.title}
            </div>
            <div style={{fontSize: "16px", color: "#666", marginBottom: "8px"}}>
                {props.result?.type}
            </div>
            <div style={{fontSize: "14px", color: "#333"}}>
                {props.result?.short_description}
            </div>
            <div className="search-result-action-container">
                {
                    props.result?.object_id && props.result?.type === 'Person' &&
                    <div className="search-result-action-button"
                         onClick={() => navigate(`/search/person/${props.result?.object_id}`)}>
                        <FaEye size={20}/>
                    </div>

                }
                {
                    props.result?.object_id &&
                    <div className="search-result-action-button" onClick={() => navigate(`/graph/${props.result?.object_id}`)}>
                        <FaCircleNodes size={20}/>
                    </div>
                }
            </div>

        </div>

    );
}