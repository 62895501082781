interface Props {
    searchHandler: Function
}

export default function SearchBar(props: Props) {
    return (
        <div
            style={{
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                display: "flex",
                justifyContent: "start",
                gap: "0.5em",
                alignItems: "center",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add this line for box shadow
            }}
        >

            <img src="/icons/search.svg" alt="looking-glass" height={16} width={16}/>
            <form style={{width: "100%"}} onSubmit={(e) => {
                e.preventDefault();
                props.searchHandler(e.currentTarget.searchTerm.value);
            }}>

                <input
                    id="searchTerm"
                    style={{
                        width: "100%",
                        resize:"none",
                        border: 'none',
                        outline: "none",
                        zIndex: "1",
                    }}
                />
            </form>

        </div>
    );
}