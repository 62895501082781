import React, {ReactNode} from 'react';
import {colours} from "../theme/theme";
import {Link} from "react-router-dom";

interface Props {
    navItem: string
    image: ReactNode
    navSelection: string
    setNavSelection: Function
}

export default function NavItem(props: Props) {
    return (
        <Link to={`/${props.navItem}`} style={props.navSelection === props.navItem ? styles.selectedNavItem : styles.navItem} onClick={()=>props.setNavSelection(props.navItem)}>
            {props.image}
        </Link>
    );
};

const styles = {
    navItem: {
        padding: '12px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colours.primary,
        backgroundColor: '#f9f9f9',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
    },
    selectedNavItem: {
        padding: '12px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colours.primary,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        color: '#fff',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease', // Smooth transition
    },
};