import React from 'react';
import {FaSearch, FaUser, FaUpload, FaLock} from 'react-icons/fa'; // Import icons
import NavItem from "../../components/nav/NavItem";
import {useAuth} from "../../components/auth/AuthProvider";
import {Link} from "react-router-dom";

interface Props {
    navSelection: string
    setNavSelection: Function
}

export default function Navbar(props: Props) {
    const {user} = useAuth();

    return (
        <div style={styles.navBar}>
            <NavItem navItem={'search'} image={<FaSearch size={20}/>} navSelection={props.navSelection}
                     setNavSelection={props.setNavSelection}/>
            <NavItem navItem={'profile'} image={<FaUser size={20}/>} navSelection={props.navSelection}
                     setNavSelection={props.setNavSelection}/>
            {user.is_admin &&
                <NavItem navItem={'upload'} image={<FaUpload size={20}/>} navSelection={props.navSelection}
                         setNavSelection={props.setNavSelection}/>}
            <Link to={'logout'} style={{color:'black'}}><NavItem navItem={'logout'} image={<FaLock size={20}/>} navSelection={props.navSelection}
                           setNavSelection={props.setNavSelection}/></Link>
        </div>
    );
};

const styles = {
    navBar: {
        paddingTop: '12px',
        display: 'flex',
        justifyContent: 'space-around',
        gap: '1em',
        alignItems: 'center',
        width: 'fit-content',
        margin: 'auto',
    }
};