import * as React from 'react';
import {PanelGroup, Panel, PanelResizeHandle} from "react-resizable-panels";
import {colours} from "../components/theme/theme";
import Navbar from "../components/nav/Navbar";

interface Props {
    sidebar: React.ReactNode,
    content: React.ReactNode,
    navSelection: string,
    setNavSelection: Function
}


export default function MainLayout(props: Props) {
    return (
        <div style={{height: window.innerHeight, width: window.innerWidth}}>
            <PanelGroup direction="horizontal" autoSaveId="persistence">
                <Panel>
                    <PanelGroup direction="vertical">
                        <Panel style={{overflow: 'auto'}}>
                            <Navbar navSelection={props.navSelection} setNavSelection={props.setNavSelection}/>
                            {props.sidebar}
                        </Panel>
                    </PanelGroup>
                </Panel>
                <PanelResizeHandle style={{width: 3, backgroundColor: 'black'}}/>
                <Panel defaultSize={70}>
                    <PanelGroup direction="vertical">
                        <Panel style={{backgroundColor: colours.primary}}>
                            {props.content}
                        </Panel>
                    </PanelGroup>
                </Panel>
            </PanelGroup>
        </div>
    );
}