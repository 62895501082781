import './App.css';
import Login from "./pages/login";
import Logout from "./pages/logout";
import Register from "./pages/register";
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import PrivateRoute from "./components/auth/PrivateRoute";
import FourOhFour from "./pages/404";
import {useAuth} from "./components/auth/AuthProvider";
import Explorer from "./components/explorer/Explorer";
import Profile from "./components/profile/profile";
import Person from "./components/profile/person";
import Uploader from "./components/uploader";
import * as React from "react";
import {useState} from "react";

function App() {
    const auth = useAuth()
    const [navSelection, setNavSelection] = useState<string>('search');


    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={auth.user ? <Navigate to="/"/> : <Login/>}/>
                    <Route path="/register" element={auth.user ? <Navigate to="/"/> : <Register/>}/>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/"
                               element={<Explorer navSelection={navSelection} setNavSelection={setNavSelection}/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/profile"
                               element={<Profile navSelection={navSelection} setNavSelection={setNavSelection}/>}/>
                        <Route path="/search"
                               element={<Explorer navSelection={navSelection} setNavSelection={setNavSelection}/>}>
                            <Route path="upload/" element={<Uploader/>}/>
                            <Route path="person/:id" element={<Person/>}/>
                        </Route>
                        <Route path="/graph/:id" element={<Explorer navSelection={navSelection} setNavSelection={setNavSelection}/>}/>
                        <Route path="/upload" element={<Uploader/>}/>

                    </Route>
                    <Route path="*" element={<FourOhFour/>}/>
                </Routes>
            </BrowserRouter>

        </div>
    );
}

export default App;
