import {Outlet, Navigate} from 'react-router-dom'
import {useAuth} from "./AuthProvider";



export default function PrivateRoute() {
    const {user} = useAuth();
    console.log('user from context', user)

    return user ? <Outlet/> : <Navigate to="/login"/>
}
