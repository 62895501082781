import React from 'react';
import {Node} from '../../interfaces/Node';

interface Props {
    node: Node;
}

export default function Content(props: Props) {

    const items = Object.keys(props.node.data).map((key) => {
        // Replace underscores with spaces and capitalize each word
        const formattedKey = key
            .replace(/_/g, ' ') // Replace underscores with spaces
            .split(' ')         // Split into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' ');         // Join the words back together

        return (
            <tr key={key}>
                <td style={{padding:5, fontWeight: 'bold'}}>{formattedKey}</td>
                <td style={{padding:5, paddingLeft:20}}>{props.node.data[key]}</td>
            </tr>
        );
    });


    return (
        <div style={{padding: '10px'}}>
            {/*<table style={{width: '100%', borderCollapse: 'collapse'}}>*/}
            {/*    <tbody>*/}
            <table>
                {items}</table>
                {/*</tbody>*/}
            {/*</table>*/}
        </div>
    );
};