import React from 'react';
import {Box, Typography, IconButton} from '@mui/material';
import Content from './Content';
import CloseIcon from '@mui/icons-material/Close';
import {Node} from '../../interfaces/Node';

interface PopupProps {
    node: Node;
    visible: boolean;
    setVisibility: any;
}


class GraphPopup extends React.Component<PopupProps> {
    constructor(props: PopupProps) {
        super(props);
    }

    handleClose = () => {
        this.props.setVisibility(false);
    };

    render() {
        if (!this.props.visible) return null;

        return (
            <Box
                sx={{
                    position: 'absolute',
                    textAlign: 'left',
                    top: {xs: 10, sm: 20},
                    left: {xs: 0, sm: 20},
                    maxWidth: '40%',
                    bgcolor: 'white',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    p: 2,
                    borderRadius: 2,
                }}
            >
                <IconButton onClick={this.handleClose} style={{ position: 'absolute', right: 10 }}>
                    <CloseIcon sx={{color: '#666'}}/>
                </IconButton>
                <Content node={this.props.node}></Content>
            </Box>
        );
    }
}

export default GraphPopup;
