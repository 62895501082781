import '../../assets/css/text.css'
import '../../assets/css/cards.scss'
import '../../assets/css/lists.scss'
import React, {useEffect, useState} from "react";
import axios from "axios";

interface RelationshipItem {
    type: string;
    name: string;
    description: string;
}

interface Relationships {
    [key: string]: RelationshipItem[]; // Each key corresponds to an array of RelationshipItem
}

interface Props {
    id: string | undefined
}

export default function ProfileContent(props: Props) {
    const [relationships, setRelationships] = useState<Relationships | null>(null);

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_BASE_URL + 'user/relationships',
            {'object_id': props.id},
            {
                withCredentials: true
            }
        ).then(response => {
            setRelationships(response.data as Relationships);
        }).catch(error => {
            console.error(error);
        });

        console.log(relationships);
    }, []);

    return (
        <div className={'profile-content-container'}>
            {relationships && Object.keys(relationships).map((section: string) => (
                <div key={section} className={'profile-content-card'}>
                    <h2>{section}s</h2>
                    <ul className={'profile-content-list'}>
                        {relationships[section].map((item: RelationshipItem, index: number) => (
                            <li key={index} className={'search-result'}>
                                <strong>{item.name}</strong> {item.description}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
            {!relationships && (<div>Nothing to see here...</div>)}
        </div>
    )
}