import * as React from 'react';
import axios from "axios";
import {useEffect, useState} from "react";
import GraphVisual from "./GraphVisual";
import {Graph} from "../../interfaces/Graph";
import {Node} from "../../interfaces/Node";
import {Link} from "../../interfaces/Link";
import Search from "../search/Search"
import MainLayout from "../../layouts/main_layout";
import {useLocation, Outlet, useParams} from "react-router-dom";

interface Props {
    navSelection: string,
    setNavSelection: Function
}

export default function Explorer(props: Props) {

    const [sourceObjectIds, setSourceObjectIds] = useState<number[]>([]);
    const [graphDepth, setGraphDepth] = useState<number>(2);
    const [graph, setGraph] = useState<Graph | null>(null);
    const params = useParams();


    useEffect(() => {
        if (params.id) {
            if (params.id) {
                let objectIds: number[] = []
                objectIds.push(parseInt(params.id))
                setSourceObjectIds(objectIds);
            }
        }
    }, []);

    useEffect(() => {
        const fetchGraphData = async () => {
            const graph: Graph | null = await loadGraphData(sourceObjectIds, graphDepth);
            setGraph(graph);
        };

        fetchGraphData();
    }, [sourceObjectIds, graphDepth]);

    const loadGraphData = async (object_ids: number[], depth: number): Promise<Graph | null> => {
        let graph: Graph | null = null;

        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + 'graph',
                {object_ids: object_ids, depth: depth},
                {withCredentials: true}
            );

            const nodes: Node[] = (response.data.nodes?.map((node: any) => ({
                id: node.id,
                type: node.type,
                data: JSON.parse(node.data),
                last_modified: node.last_modified,
                created: node.created,
                deleted: node.deleted,
            })) || []) as Node[];

            const links: Link[] = (response.data.links?.map((link: any) => ({
                source: link.source_object,
                target: link.target_object,
                created: link.created,
                last_modified: link.last_modified,
                deleted: link.deleted,
            })) || []) as Link[];

            graph = {nodes: nodes, links: links};
        } catch (error) {
            console.log(error);
        }

        return graph;
    };

    let location = useLocation();

    return (
        <MainLayout
            navSelection={props.navSelection}
            setNavSelection={props.setNavSelection}
            sidebar={
                <Search setSourceObjectIds={setSourceObjectIds} setGraphDepth={setGraphDepth}/>
            }
            content={
                location.pathname === '/search' || location.pathname === '/' || location.pathname.split("/")[1] === 'graph' ?
                    <GraphVisual graph={graph} loadGraphData={loadGraphData}
                                 graphDepth={graphDepth}
                                 setGraphDepth={setGraphDepth}/> :
                    <Outlet/>

            }
        />
    );
}