import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {useAuth} from "../components/auth/AuthProvider";
import {colours} from "../components/theme/theme";
import {Link} from "react-router-dom";

const Logout = () => {
    const {logout} = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            textAlign: 'center',
            backgroundColor: '#f9f9f9',
            padding: '20px'
        }}>
            <h1 style={{
                fontSize: '24px',
                color: '#333'
            }}>
                Are you sure you want to logout?
            </h1>
            <div style={{display: 'flex', gap: '10px'}}>
                <Link to={'/'}>
                    <button
                        style={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            color: '#fff',
                            backgroundColor: colours.primary,
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                            display: 'inline-block',
                        }}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = colours.primary}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = colours.primary}
                    >
                        Go Back
                    </button>
                </Link>
                <button
                    onClick={handleLogout}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        color: '#fff',
                        backgroundColor: '#d9534f',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        display: 'inline-block',
                        transition: 'background-color 0.3s',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#c9302c'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#d9534f'}
                >
                    Logout
                </button>
            </div>

        </div>
    );
};

export default Logout;
